<!--@Time : 2021/11/16 10:59-->
<!--@Author : WangHaoRan-->
<!--@Annotation :-->
<template>
	<div>
		<Header></Header>
		<v-row>
			<v-col class="d-flex justify-center" cols="12" md="12">
				<v-color-picker
					v-model="color"
					width="600"
					canvas-height="400"
				></v-color-picker>
			</v-col>
		</v-row>
		<v-row>
			<v-col class="d-flex justify-center" cols="12" md="12">
				<v-btn
					@click="changeTheme"
					:style="{ background: color }"
					class="white--text"
					small
				>
					Set Theme Color
				</v-btn>
			</v-col>
		</v-row>
	</div>
</template>

<script>
import Header from "@/components/Header";
export default {
	name: "Theme",
	components: { Header },
	data() {
		return {
			color: "#67B1CA",
		};
	},
	computed: {},
	methods: {
		changeTheme() {
			this.$vuetify.theme.themes.light.primary = this.color;
			this.$vuetify.theme.themes.dark.primary = this.color;
		},
	},
};
</script>

<style scoped>
.color {
	width: 100px;
	height: 100px;
	background: #00acc1;
}
</style>